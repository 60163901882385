<template>
  <account-code-select
    v-model="sCode"
    payment-method-code="NINGUNO"
    :disabled="disabled"
  >
    <template #append>
      <v-btn
        :disabled="btnDisabled"
        :loading="!!sCode && loading"
        color="primary"
        depressed
        icon
        outlined
        x-small
        @click="onSave"
      >
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </template>
  </account-code-select>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/app";
import AccountCodeSelect from "@/modules/accounts/components/AccountCodeSelect.vue";
import { CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import { CurrencyRate, CurrencyRates } from "@planetadeleste/vue-mc-gw";

@Component({
  components: { AccountCodeSelect },
})
export default class CompanyCurrencyAccountCode extends Vue {
  @Prop({ type: Object, required: true }) readonly value!: CurrencyData;
  @Prop(Number) readonly companyId!: number;
  @Prop(Boolean) readonly disabled!: number;

  loading = false;
  obCurrencyRate: CurrencyRate | null | undefined = null;

  get company() {
    return AppModule.company;
  }

  get iCompanyId(): number {
    return this.companyId || this.company.id;
  }

  get btnDisabled(): boolean {
    return (
      !this.sCode ||
      !this.obCurrencyRate ||
      !this.obCurrencyRate.isDirty("code_account")
    );
  }

  get sCode() {
    return this.obCurrencyRate
      ? this.obCurrencyRate.get("code_account", null)
      : null;
  }

  set sCode(sValue: string) {
    if (!this.obCurrencyRate) {
      return;
    }

    this.obCurrencyRate.set("code_account", sValue);
  }

  async load() {
    this.loading = true;
    const obCollection = new CurrencyRates();
    // @ts-ignore
    await obCollection.filterBy({ companyGrouped: this.iCompanyId }).fetch();
    this.obCurrencyRate = obCollection.find({ currency_id: this.value.id });
    this.loading = false;
  }

  async onSave() {
    if (!this.obCurrencyRate || !this.obCurrencyRate.isDirty("code_account")) {
      return;
    }

    this.loading = true;
    await this.obCurrencyRate.store();
    await this.load();
  }

  mounted() {
    this.load();
  }
}
</script>
